


import React, { useState, useEffect } from 'react';
import { TextField, Divider, FormControl, InputLabel, Select, MenuItem, Button, Avatar, Box, Card, CardContent } from '@mui/material';
import { Typography, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import Grid from '@mui/material/Grid2';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom'; // นำเข้า useNavigate
import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const EditEmployee = () => {
  const navigate = useNavigate(); // สร้าง instance ของ useNavigate

  const [employee, setEmployee] = useState({
    personal_id: '',
    category_type_id: '',
    employee_type_id: '',
    academic_type_id: '',
    dept_id: '',
    manage_id: '',
    starting_date: '',
    resign_date: ''

  });

  const [department, setDepartment] = useState([]);

  const fetchDepartment = async () => {
    const response = await axios.get('https://sci-sskru.com/authen/department');
    setDepartment(response.data.department);

  };

  const [management, setManagement] = useState([]);

  const fetchManagement = async () => {
    const response = await axios.get('https://sci-sskru.com/authen/management');
    setManagement(response.data.management);

  };

  const [academic, setAcademic] = useState([]);

  const fetchAcademic = async () => {
    const response = await axios.get('https://sci-sskru.com/authen/academic');
    setAcademic(response.data.academic);

  };

  const [employeelist, setEmployeelist] = useState([]);

  const fetchEmployeelist = async () => {
    const response = await axios.get('https://sci-sskru.com/authen/employee');
    setEmployeelist(response.data.employee);

  };


  const getPersonalIdFromToken = () => {
    const token = localStorage.getItem('hrm-sci-token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        return decodedToken.personal_id;
      } catch (error) {
        console.error('Failed to decode token:', error);
      }
    }
    console.log("Read token error !!");
    return null;
  };

  const personalId = getPersonalIdFromToken();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = localStorage.getItem('hrm-sci-token');
        const response = await axios.post('https://sci-sskru.com/authen/profile', {
          personal_id: personalId,
        }, { headers: { Authorization: `Bearer ${token}` } });

        const profileData = response.data.profile;
        profileData.starting_date = new Date(profileData.starting_date).toISOString().split('T')[0];
        profileData.resign_date = new Date(profileData.resign_date).toISOString().split('T')[0];
    
        setEmployee(profileData);


      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    fetchProfile();
    fetchDepartment();
    fetchManagement();
    fetchAcademic();
    fetchEmployeelist();

  }, [personalId]);

  
  const handleSave = async () => {
    try {
      const token = localStorage.getItem('hrm-sci-token');
      const response = await axios.post('https://sci-sskru.com/authen/update-employee', employee, {
        headers: {
          'Content-Type': 'application/json',  
          Authorization: `Bearer ${token}` 
        },
      });
      showSaveSuccessful();
      //alert('Profile updated successfully!');
      //navigate('/profile/employee');
    } catch (error) {
      showSaveFail();
      //console.error('Error updating profile:', error);
      //alert('An error occurred while updating the profile.');
    }
  };

  
  const showSaveSuccessful = () => {
    withReactContent(swal).fire({
      title: 'Profile Update Successful',
      text: 'You have successfully updated profile!',
      icon: 'success',
      confirmButtonText: 'OK',
    }).then((result) => {
      if (result.isConfirmed) {
          // Navigate to the new page upon clicking "OK"
          navigate('/profile/employee');
      }
  });
  }
  const showSaveFail = () => {
    withReactContent(swal).fire({
      title: 'Update Failed',
      text: 'Correct input data. Please try again.',
      icon: 'error',
      confirmButtonText: 'OK',
    })
  }

  return (


    <Box sx={{ width: '100%' }}>

      <Card sx={{ minWidth: 400, width: '100%' }}>
        <CardContent>

          <Divider sx={{ my: 2 }} />
          <Grid size={12}>
            <Typography variant="h6" gutterBottom >
              ข้อมูลหน่วยงานสังกัด
            </Typography>

          </Grid>
          <Grid container spacing={2}>
            <Grid size={4} >
              <FormControl fullWidth margin="normal" >
                <InputLabel id="title-label">ประเภทสายงาน</InputLabel>
                <Select
                  labelId="title-label"
                  id="category_type_id"
                  value={employee.category_type_id}
                  label="title"
                  onChange={(e) => setEmployee({ ...employee, category_type_id: e.target.value })}
                >
                  <MenuItem value={1}>สายวิชาการ</MenuItem>
                  <MenuItem value={2}>สายสนับสนุน</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid size={4} >
              <FormControl fullWidth margin="normal" >
                <InputLabel id="title-label">ประเภทบุคลากร</InputLabel>
                <Select
                  labelId="title-label"
                  id="employee_type_id"
                  value={employee.employee_type_id}
                  label="title"
                  onChange={(e) => setEmployee({ ...employee, employee_type_id: e.target.value })}
                >
                  {	employeelist.map((employeelist) => (
                    <MenuItem key={	employeelist.employee_type_id } value={	employeelist.employee_type_id }>
                      {	employeelist.employee_type_name	}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid size={4} >
              <FormControl fullWidth margin="normal" >
                <InputLabel id="title-label">ตำแหน่งทางวิชาการ</InputLabel>
                <Select
                  labelId="title-label"
                  id="academic_type_id"
                  value={employee.academic_type_id}
                  label="title"
                  onChange={(e) => setEmployee({ ...employee, academic_type_id: e.target.value })}
                >
                  {	academic.map((academic) => (
                    <MenuItem key={	academic.academic_type_id} value={	academic.academic_type_id}>
                      {	academic.academic_type_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid size={4} >
              <FormControl fullWidth margin="normal" >
                <InputLabel id="title-label">สังกัดสาขา</InputLabel>
                <Select
                  labelId="title-label"
                  id="dept_id"
                  value={employee.dept_id}
                  label="title"
                  onChange={(e) => setEmployee({ ...employee, dept_id: e.target.value })}
                >
                  {department.map((department) => (
                    <MenuItem key={department.dept_id} value={department.dept_id}>
                      {department.dept_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid size={4} >
              <FormControl fullWidth margin="normal" >
                <InputLabel id="title-label">ตำแหน่งการบริหาร</InputLabel>
                <Select
                  labelId="title-label"
                  id="manage_id"
                  value={employee.manage_id}
                  label="title"
                  onChange={(e) => setEmployee({ ...employee, manage_id: e.target.value })}
                >
                  {management.map((management) => (
                    <MenuItem key={management.manage_id} value={management.manage_id}>
                      {management.manage_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid size={4} >
              <FormControl fullWidth margin="normal" >
                <InputLabel id="title-label">คณะ</InputLabel>
                <Select
                  labelId="title-label"
                  label="title"
                  value={1}
                  disabled
                >
                  <MenuItem value={1}>ศิลปศาสตร์และวิทยาศาสตร์</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid size={4} >
              <TextField
                fullWidth
                label="วันเริ่มปฏิบัติงาน (เดือน/วัน/ปี คศ.)"
                type="date"
                value={employee.starting_date}
                

                margin="normal"
              />
            </Grid>
            <Grid size={4} >
              <TextField
                fullWidth
                label="วันสิ้นสุดการทำงาน (เดือน/วัน/ปี คศ.)"
                type="date"
                value={employee.resign_date}
                disabled

                margin="normal"
              />
            </Grid>
          </Grid>

          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" color="primary" startIcon={<SaveOutlinedIcon />} onClick={handleSave}>
              บันทึกข้อมูล
            </Button>

          </Box>






        </CardContent>
      </Card>



    </Box>
  );
};

export default EditEmployee;
