import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Divider,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom'; // นำเข้า useNavigate

function Education() {
  const navigate = useNavigate(); // สร้าง instance ของ useNavigate
  const [education, setEducation] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getPersonalIdFromToken = () => {
    const token = localStorage.getItem("hrm-sci-token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        return decodedToken.personal_id;
      } catch (error) {
        console.error("Failed to decode token:", error);
      }
    }
    console.log("Read token error !!");
    return null;
  };

  const personalId = getPersonalIdFromToken();

  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('hrm-sci-token');
        const response = await axios.post("https://sci-sskru.com/authen/education", {
          personal_id: personalId,
        }, { headers: { Authorization: `Bearer ${token}` } });
        setEducation(response.data.education);
        //console.log(response.data.education);
      } catch (error) {
        if(error.response?.data?.message == "Profile not found") {
          navigate('/profile/edit-education');
        }
      } finally {
        setLoading(false);
      }
    };
    fetchProfile();
  }, [personalId]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p style={{ color: "red" }}>{error}</p>;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <Card sx={{ minWidth: 400, width: "100%" }}>
        <CardContent>
          <Box sx={{ display: "flex" }}>
            <Paper sx={{ minWidth: 350, width: "100%" }}>
              <Typography variant="h6" align="center" gutterBottom>
                ตารางข้อมูลการศึกษา
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="body1" color="textPrimary">
                          <strong>วุฒิการศึกษา</strong>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" color="textPrimary">
                          <strong>คุณวุฒิ</strong>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" color="textPrimary">
                          <strong>มหาวิทยาลัย</strong>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" color="textPrimary">
                          <strong>ปีที่จบการศึกษา</strong>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {education.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography variant="body1" color="textPrimary">
                            {row.degree_name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1" color="textPrimary">
                            {row.major}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1" color="textPrimary">
                            {row.university_name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1" color="textPrimary">
                            {row.year}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Actions */}
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              href={`${process.env.PUBLIC_URL}/profile/edit-education`}
              variant="contained"
              color="primary"
              startIcon={<EditOutlinedIcon />}
              sx={{ mx: 1 }}
            >
              แก้ไขข้อมูล
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default Education;
