import React, { useState } from 'react';
import { Link, Avatar, Button, TextField, Box, Typography, Container, CssBaseline } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom'; // นำเข้า useNavigate
import axios from 'axios';
function SignIn() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // สร้าง instance ของ useNavigate

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Logic สำหรับการ Login
    // alert(username);
    // alert(password);
    setError('Invalid username or password');
    try {
      const response = await axios.post('https://sci-sskru.com/authen/login', {
        "uname" : username,
        "pwd" : password
      });

      const { token } = response.data; // รับ token จากการตอบกลับของ API
      if (token) {
        localStorage.setItem('hrm-sci-token', token); // เก็บ token ใน localStorage
        alert('Login successful!');
        // ทำการ redirect หรือดำเนินการอื่นๆ ที่ต้องการหลังจากเข้าสู่ระบบสำเร็จ
        navigate('/profile/personal');
      } else {
        alert('Invalid username or password !');
      }
    } catch (err) {
      
      setError('Invalid username or password');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline /
      >
        
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          เข้าสู่ระบบ
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="ชื่อผู้ใช้"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="รหัสผ่าน"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            เข้าสู่ระบบ
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default SignIn;
