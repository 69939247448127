import React, { useState, useEffect } from 'react';
import { TextField, Divider, FormControl, InputLabel, Select, MenuItem, Button, Avatar, Box, Card, CardContent, major } from '@mui/material';
import { IconButton, Typography, FormGroup, FormControlLabel, Checkbox, TableContainer, TableRow, TableCell, Paper, Table, TableHead, TableBody } from '@mui/material';
import Grid from '@mui/material/Grid2';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Autocomplete from '@mui/material/Autocomplete';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const EditEducation = () => {


  const [education, setEducation] = useState([]);;
  const [educationData, setEducationData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [university, setUniversity] = useState([]);

  const [editingEducationId, setEditingEducationId] = useState(null);


  const fetchUniversity = async () => {
    const response = await axios.get('https://sci-sskru.com/authen/university');
    setUniversity(response.data.university);

  };

  const fetchProfile = async () => {
    setLoading(true);
    try {
      //console.log(education)
      const token = localStorage.getItem('hrm-sci-token');
      const decodedToken = jwtDecode(token);
      const response = await axios.post('https://sci-sskru.com/authen/education', {
        personal_id: decodedToken.personal_id
      }, { headers: { Authorization: `Bearer ${token}` } });
      setEducationData(response.data.education);
     
    } catch (error) {
      console.log("Setup New Education");
      //setError(error.response?.data?.message || 'ไม่สามารถเข้าถึงข้อมูลได้ !!');
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {


    const getPersonalIdFromToken = async () => {
      const token = localStorage.getItem('hrm-sci-token');
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          setEducation({ personal_id: decodedToken.personal_id })
          return decodedToken.personal_id;

        } catch (error) {
          console.error('Failed to decode token:', error);
        }
      }
      console.log("Read token error !!");
      return null;
    };
  }, []);

  useEffect(() => {
    
    fetchProfile();
    fetchUniversity();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }



  const handleEdit = (edu) => {
    console.log("Selected Education Data for Edit:", edu); // Log to confirm `edu` is being passed correctly
    setEducation({
      degree_name: edu.degree_name || '',
      major: edu.major || '',
      university_id: edu.university_id || '',
      year: edu.year || '',
    });
    setEditingEducationId(edu.education_id);
  };
  
 
  const handleSave = async () => {
    try {
      const token = localStorage.getItem('hrm-sci-token');
      const decodedToken = jwtDecode(token);
      const updatedEducation = { ...education, personal_id: decodedToken.personal_id };
  
      if (editingEducationId) {
        // เรียกใช้ API สำหรับการแก้ไขข้อมูล
        await axios.put(`https://sci-sskru.com/authen/update-education/${editingEducationId}`, updatedEducation, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        showSaveSuccessful();
        setEditingEducationId(null); // เคลียร์ค่าเมื่อเสร็จสิ้นการแก้ไข
      } else {
        // เรียกใช้ API สำหรับเพิ่มข้อมูลใหม่
        await axios.post('https://sci-sskru.com/authen/update-education', updatedEducation, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        showSaveSuccessful();
      }
  
      fetchProfile(); // อัปเดตข้อมูลตาราง
    } catch (error) {
      showSaveFail();
    }
  };



  const handleSaveImg = () => {
    // Logic to handle saving the data
    console.log('Img saved');
  };

  const handleTitleChange = (event) => {

  };
  const handleDelete = async (index,education_id) => {
    const updatedData = educationData.filter((_, i) => i !== index);
    setEducationData(updatedData);

    try {
      const token = localStorage.getItem('hrm-sci-token');
      const response = await axios.delete(`https://sci-sskru.com/authen/del-edu?education_id=${education_id}`, {
        headers: {
          Authorization: `Bearer ${token}` 
        },
      });
      showDelSuccessful();
    } catch (error) {
      showSaveFail();
      //console.error('Error fetching zipcode:', error);
    }
    
  };
  const showSaveSuccessful = () => {
    withReactContent(swal).fire({
      title: 'Add Education Successful',
      text: 'You have successfully add education!',
      icon: 'success',
      confirmButtonText: 'OK',
    }).then((result) => {
      if (result.isConfirmed) {
        setEducation({
          degree_name: '',
          major: '',
          university_name: '',
          year: '',
          university_id: '',
          });
          // Navigate to the new page upon clicking "OK"
          // navigate('/profile/personal');
      }
    });
  }
  const showDelSuccessful = () => {
    withReactContent(swal).fire({
      title: 'Delete Education Successful',
      text: 'You have successfully delete education!',
      icon: 'success',
      confirmButtonText: 'OK',
    }).then((result) => {
      if (result.isConfirmed) {
          // Navigate to the new page upon clicking "OK"
          // navigate('/profile/personal');
      }
    });
  }
  const showSaveFail = () => {
    withReactContent(swal).fire({
      title: 'Update Failed',
      text: 'Correct input data. Please try again.',
      icon: 'error',
      confirmButtonText: 'OK',
    })
  }

  const handleUniversityChange = (event, value) => {
    setEducation({ ...education, university_id: value ? value.university_id : null });
  };

  return (


    <Box sx={{ width: '100%' }}>

      <Card sx={{ minWidth: 400, width: '100%' }}>
        <CardContent>

          <Divider sx={{ my: 2 }} />
          <Grid size={12}>
            <Typography variant="h6" gutterBottom >
              ข้อมูลการศึกษา
            </Typography>

          </Grid>
          <Grid container spacing={2}>
            <Grid size={3} >
              <FormControl fullWidth margin="normal" >
                <InputLabel id="title-label">วุฒิการศึกษา</InputLabel>
                <Select
                labelId="title-label"
                id="degree_name"
                label="วุฒิการศึกษา"
                value={education.degree_name || ""} // Fallback to an empty string if degree_name is undefined
                onChange={(e) => setEducation({ ...education, degree_name: e.target.value })}
              >
                <MenuItem value={"ปริญญาตรี"}>ปริญญาตรี</MenuItem>
                <MenuItem value={"ปริญญาโท"}>ปริญญาโท</MenuItem>
                <MenuItem value={"ปริญญาเอก"}>ปริญญาเอก</MenuItem>
              </Select>
              </FormControl>
            </Grid>
            <Grid size={3} >
              <TextField
                fullWidth
                label="คุณวุฒิ รูปแบบ วท.ม. (วิศวกรรมซอฟต์แวร์)"
                value={education.major || ''} // Bind the value to the state
                onChange={(e) => setEducation({ ...education, major: e.target.value })}
                margin="normal"
                placeholder='วท.ม. (วิศวกรรมซอฟต์แวร์)'
              />
            </Grid>
            <Grid size={3} >
              
                

                <Autocomplete
                  options={university} // รายการมหาวิทยาลัยที่ดึงมาจาก API
                  getOptionLabel={(option) => option.university_name} // กำหนดให้แสดงชื่อมหาวิทยาลัยในแต่ละตัวเลือก
                  renderInput={(params) => (
                    <TextField {...params} label="มหาวิทยาลัย" margin="normal" />
                  )}
                  value={university.find((uni) => uni.university_id === education.university_id) || null} // ค่าปัจจุบันที่เลือกแสดงในฟอร์ม
                  onChange={handleUniversityChange} // เมื่อมีการเลือกมหาวิทยาลัย จะเรียกฟังก์ชันนี้เพื่อเก็บค่าที่เลือก
                  isOptionEqualToValue={(option, value) => option.university_id === value.university_id} // ใช้ตรวจสอบว่าตัวเลือกที่เลือกอยู่แล้วหรือไม่โดยเทียบค่า id
                />

              
            </Grid>
            <Grid size={3} >
              <TextField
                fullWidth
                label="ปีที่จบการศึกษา"
                value={education.year || ''} // Bind the value to the state
                onChange={(e) => setEducation({ ...education, year: e.target.value })}
                margin="normal"
              />
            </Grid>
          </Grid>



          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" color="primary" startIcon={<SaveOutlinedIcon />} onClick={handleSave}>
              เพิ่มข้อมูล
            </Button>

          </Box>
          <Divider sx={{ my: 4 }} />

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><Typography variant="body1" color="textPrimary"><strong>วุฒิการศึกษา</strong></Typography></TableCell>
                  <TableCell><Typography variant="body1" color="textPrimary"><strong>คุณวุฒิ</strong></Typography></TableCell>
                  <TableCell><Typography variant="body1" color="textPrimary"><strong>มหาวิทยาลัย</strong></Typography></TableCell>
                  <TableCell><Typography variant="body1" color="textPrimary"><strong>ปีที่จบการศึกษา</strong></Typography></TableCell>
                  <TableCell><Typography variant="body1" color="textPrimary"><strong>แก้ไขข้อมูล</strong></Typography></TableCell>
                  <TableCell><Typography variant="body1" color="textPrimary"><strong>ลบข้อมูล</strong></Typography></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {educationData.map((edu, index) => (
                  <TableRow key={edu.education_id} >
                    <TableCell><Typography variant="body1" color="textPrimary">{edu.degree_name}</Typography></TableCell>
                    <TableCell><Typography variant="body1" color="textPrimary">{edu.major}</Typography></TableCell>
                    <TableCell><Typography variant="body1" color="textPrimary">{edu.university_name}</Typography></TableCell>
                    <TableCell><Typography variant="body1" color="textPrimary">{edu.year}</Typography></TableCell>
                    <TableCell>
                    <IconButton color="primary" onClick={() => handleEdit(edu)}>
                      <EditOutlinedIcon />
                    </IconButton>
                  </TableCell>
                    <TableCell><IconButton color="error" onClick={() => handleDelete(index, edu.education_id)}>
                      <DeleteForeverOutlinedIcon />
                    </IconButton></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>






        </CardContent>
      </Card>



    </Box>
  );
};

export default EditEducation;
