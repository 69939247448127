import React, { useState, useEffect } from 'react';
import { TextField, Divider, FormControl, InputLabel, Select, MenuItem, Button, Avatar, Box, Card, CardContent } from '@mui/material';
import { Typography, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import Grid from '@mui/material/Grid2';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom'; // นำเข้า useNavigate
import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const EditPersonal = () => {
  const navigate = useNavigate(); // สร้าง instance ของ useNavigate

  const [profile, setProfile] = useState({
    personal_id: '',
    title_id: '',
    title_name: '',
    first_name: '',
    last_name: '',
    first_name_en: '',
    last_name_en: '',
    birth_date: '',
    mobile: '',
    house_number: '',
    moo: '',
    soi: '',
    road: '',
    sub_district: '',
    district: '',
    province: '',
    subdistrict_name: '',
    district_name: '',
    province_name: '',
    zipcode: '',
    current_house_number: '',
    current_moo: '',
    current_soi: '',
    current_road: '',
    current_sub_district: '',
    current_district: '',
    current_province: '',
    current_subdistrict_name: '',
    current_district_name: '',
    current_province_name: '',
    current_zipcode: '',
    email: '',
    email_uni_google: '',
    email_uni_microsoft: '',
    fb_acc: '',
    line_acc: '',
    personal_site: '',
    img: '',

  });

  const [useHomeAddress, setUseHomeAddress] = useState(false);

  const handleSave = async () => {
    try {
      const profileJson = JSON.stringify(profile);
      console.log(profile)
      // console.log(profileJson)
      const token = localStorage.getItem('hrm-sci-token');
      const response = await axios.post('https://sci-sskru.com/authen/update-profile', profile, {
        headers: {
          'Content-Type': 'application/json',  
           Authorization: `Bearer ${token}` 
        },
      });
      showSaveSuccessful();
      //alert('Profile updated successfully!');
      //navigate('/profile/personal');
    } catch (error) {
      showSaveFail();
      //console.error('Error updating profile:', error);
      //alert('An error occurred while updating the profile.');
    }
  };

  const handleSaveImg = () => {
    // Logic to handle saving the data
    console.log('Img saved');
  };



  const getPersonalIdFromToken = () => {
    const token = localStorage.getItem('hrm-sci-token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        return decodedToken.personal_id;
      } catch (error) {
        console.error('Failed to decode token:', error);
      }
    }
    console.log("Read token error !!");
    return null;
  };

  const personalId = getPersonalIdFromToken();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = localStorage.getItem('hrm-sci-token');
        const response = await axios.post('https://sci-sskru.com/authen/profile', {
          personal_id: personalId,
        }, { headers: { Authorization: `Bearer ${token}` } });

        const profileData = response.data.profile;
        profileData.birth_date = new Date(profileData.birth_date).toISOString().split('T')[0];
        //console.log(profileData.sub_district)
        setFileUrl(profileData.img)
        setProfile(profileData);
        fetchProvinces()
        fetchDistricts(profileData.province)
        fetchSubDistricts(profileData.district)
        fetchCProvinces()
        fetchCDistricts(profileData.current_province)
        fetchCSubDistricts(profileData.current_district)
        // console.log(profile.province)


      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    fetchProfile();

  }, [personalId]);



  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [subDistricts, setSubDistricts] = useState([]);


  const fetchProvinces = async () => {
    const response = await axios.get('https://sci-sskru.com/authen/provinces');
    setProvinces(response.data.provinces);

  };

  const fetchDistricts = async (provinces_id) => {
    const response = await axios.get(`https://sci-sskru.com/authen/districts?provinces_id=${provinces_id}`);
    setDistricts(response.data.districts);

  };

  const fetchSubDistricts = async (districts_id) => {
    const response = await axios.get(`https://sci-sskru.com/authen/sub_districts?districts_id=${districts_id}`);
    setSubDistricts(response.data.sub_districts);

  };

  const fetchZipcode = async (subdistricts_id) => {
    try {
      const response = await axios.get(`https://sci-sskru.com/authen/zipcode?subdistricts_id=${subdistricts_id}`);
      if (response.data.zip_code) {
        setProfile(prevProfile => ({ ...prevProfile, zipcode: response.data.zip_code }));
      }
    } catch (error) {
      console.error('Error fetching zipcode:', error);
    }
  };


  const [c_provinces, setCProvinces] = useState([]);
  const [c_districts, setCDistricts] = useState([]);
  const [c_subDistricts, setCSubDistricts] = useState([]);


  const fetchCProvinces = async () => {
    const response = await axios.get('https://sci-sskru.com/authen/provinces');
    setCProvinces(response.data.provinces);

  };

  const fetchCDistricts = async (provinces_id) => {
    const response = await axios.get(`https://sci-sskru.com/authen/districts?provinces_id=${provinces_id}`);
    setCDistricts(response.data.districts);

  };

  const fetchCSubDistricts = async (districts_id) => {
    const response = await axios.get(`https://sci-sskru.com/authen/sub_districts?districts_id=${districts_id}`);
    setCSubDistricts(response.data.sub_districts);

  };

  const fetchCZipcode = async (subdistricts_id) => {
    try {
      const response = await axios.get(`https://sci-sskru.com/authen/zipcode?subdistricts_id=${subdistricts_id}`);
      if (response.data.zip_code) {
        setProfile(prevProfile => ({ ...prevProfile, current_zipcode: response.data.zip_code }));
      }
    } catch (error) {
      console.error('Error fetching zipcode:', error);
    }
  };

  const showSaveSuccessful = () => {
    withReactContent(swal).fire({
      title: 'Profile Update Successful',
      text: 'You have successfully updated profile!',
      icon: 'success',
      confirmButtonText: 'OK',
    }).then((result) => {
      if (result.isConfirmed) {
          // Navigate to the new page upon clicking "OK"
          navigate('/profile/personal');
      }
  });
  }
  const showSaveImgSuccessful = () => {
    withReactContent(swal).fire({
      title: 'Image Update Successful',
      text: 'You have successfully updated Image!',
      icon: 'success',
      confirmButtonText: 'OK',
    })
  }
  const showSaveFail = () => {
    withReactContent(swal).fire({
      title: 'Update Failed',
      text: 'Correct input data. Please try again.',
      icon: 'error',
      confirmButtonText: 'OK',
    })
  }

  const showError = (message) => {
    withReactContent(swal).fire({
      title: 'Update Failed',
      text: message,
      icon: 'error',
      confirmButtonText: 'OK',
    })
  }

  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState("");
  const [message, setMessage] = useState("");

  const handleFileChange = (e) => {
      setFile(e.target.files[0]);
     // console.log(file);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!file) {
      setMessage("กรุณาเลือกไฟล์ก่อนอัปโหลด");
      showError(message);
      return;
  }
    const fileExtension = file.name.split('.').pop();  // ดึงนามสกุลไฟล์จากชื่อไฟล์ // ตั้งชื่อไฟล์ใหม่ด้วย personal_id + นามสกุลไฟล์
    const newFileName = personalId + '.'+ fileExtension;  // ตั้งชื่อไฟล์ใหม่ด้วย personal_id + นามสกุลไฟล์
    const newFile = new File([file], newFileName, { type: file.type });  // สร้างไฟล์ใหม่ที่มีชื่อที่แปลงแล้ว


    const formData = new FormData();
    formData.append("profilePic", newFile);  // ส่งไฟล์ที่เปลี่ยนชื่อแล้ว
    formData.append("personal_id", personalId); // personal_id ที่จะส่งไปยัง backend

    
    try {
        const response = await axios.post("https://sci-sskru.com/authen/upload", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        setMessage(response.data.message);
        setFileUrl(response.data.fileUrl); 
        showSaveImgSuccessful();
        console.log(fileUrl)
        
    } catch (error) {
        setMessage("เกิดข้อผิดพลาดในการอัปโหลดรูป");
    }
  };

  const handleCheckboxChange = async (event) => {
    const checked = event.target.checked;
    setUseHomeAddress(checked);
  
    if (checked) {
      // Copy home address to current address fields
      setProfile((prevProfile) => {
        const updatedProfile = {
          ...prevProfile,
          current_house_number: prevProfile.house_number,
          current_moo: prevProfile.moo,
          current_soi: prevProfile.soi,
          current_road: prevProfile.road,
          current_sub_district: prevProfile.sub_district,
          current_district: prevProfile.district,
          current_province: prevProfile.province,
          current_zipcode: prevProfile.zipcode,
        };
  
        // Return updated profile for state update
        return updatedProfile;
      });
  
      // Fetch dropdown data based on updated profile data
      const updatedProfile = {
        ...profile,
        current_house_number: profile.house_number,
        current_moo: profile.moo,
        current_soi: profile.soi,
        current_road: profile.road,
        current_sub_district: profile.sub_district,
        current_district: profile.district,
        current_province: profile.province,
        current_zipcode: profile.zipcode,
      };
      
      await fetchCProvinces();
      await fetchCDistricts(updatedProfile.current_province); // Use province from the updated data
      await fetchCSubDistricts(updatedProfile.current_district); // Use district from the updated data
    } else {
      // Clear current address fields when checkbox is unchecked
      setProfile((prevProfile) => ({
        ...prevProfile,
        current_house_number: '',
        current_moo: '',
        current_soi: '',
        current_road: '',
        current_sub_district: '',
        current_district: '',
        current_province: '',
        current_zipcode: '',
      }));
    }
  };

  return (


    <Box sx={{ width: '100%' }}>

      <Card sx={{ minWidth: 400, width: '100%' }}>
        <CardContent>


          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar
              alt="Supachai Thongsuk"
              src={`https://sci-sskru.com/authen${fileUrl}`}
              sx={{ width: 150, height: 150 }}

            />
            <Grid container spacing={2}>
              <div>
                <form onSubmit={handleUpload}>
                  
                    <input type="file" accept="image/png, image/gif, image/jpeg"  onChange={handleFileChange} />
                    <Button type="submit" variant="contained" color="primary" startIcon={<SaveOutlinedIcon />} >
                        Upload
                    </Button>
                </form>
              </div>
            </Grid>
          </Box>

          <Divider sx={{ my: 2 }} />
          <Grid size={12}>
            <Typography variant="h6" gutterBottom >
              ข้อมูลส่วนตัว
            </Typography>

          </Grid>
          <Grid container spacing={2}>
            <Grid size={4} >
              <FormControl fullWidth margin="normal" >
                <InputLabel id="title-label">คำนำหน้า</InputLabel>
                <Select
                  labelId="title-label"
                  id="title_id"
                  value={profile.title_id}
                  label="title"
                  onChange={(e) => setProfile({ ...profile, title_id: e.target.value })}
                >
                  <MenuItem value={1}>นาย</MenuItem>
                  <MenuItem value={2}>นาง</MenuItem>
                  <MenuItem value={3}>นางสาว</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid size={4} >
              <TextField
                fullWidth
                label="ชื่อ"
                value={profile.first_name}
                onChange={(e) => setProfile({ ...profile, first_name: e.target.value })}
                margin="normal"
              />
            </Grid>
            <Grid size={4} >
              <TextField
                fullWidth
                label="นามสกุล"
                value={profile.last_name}
                onChange={(e) => setProfile({ ...profile, last_name: e.target.value })}

                margin="normal"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid size={4} >
              <FormControl fullWidth margin="normal" >
                <InputLabel id="title-label">คำนำหน้า (En)</InputLabel>
                <Select
                  labelId="title-label"
                  id="title_id"
                  value={profile.title_id}
                  label="title"
                  onChange={(e) => setProfile({ ...profile, title_id: e.target.value })}
                >
                  <MenuItem value={1}>Mr.</MenuItem>
                  <MenuItem value={2}>Mrs.</MenuItem>
                  <MenuItem value={3}>Miss.</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid size={4} >
              <TextField
                fullWidth
                label="ชื่อ (En)"
                value={profile.first_name_en}
                onChange={(e) => setProfile({ ...profile, first_name_en: e.target.value })}
                margin="normal"
              />
            </Grid>
            <Grid size={4} >
              <TextField
                fullWidth
                label="นามสกุล (En)"
                value={profile.last_name_en}
                onChange={(e) => setProfile({ ...profile, last_name_en: e.target.value })}

                margin="normal"
              />
            </Grid>
          </Grid>

          <TextField
            fullWidth
            label="Birthdate (เดือน/วัน/ปี คศ.)"
            type="date"
            value={profile.birth_date}
            onChange={(e) => setProfile({ ...profile, birth_date: e.target.value })}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Phone Number"
            value={profile.mobile}
            onChange={(e) => setProfile({ ...profile, mobile: e.target.value })}
            margin="normal"
          />
          <Divider sx={{ my: 2 }} />
          <Grid size={12}>
            <Typography variant="h6" gutterBottom >
              ที่อยู่ตามทะเบียนบ้าน
            </Typography>

          </Grid>
          <Grid container spacing={2}>
            <Grid size={2}>
              <TextField
                fullWidth
                label="บ้านเลขที่"
                value={profile.house_number}
                onChange={(e) => setProfile({ ...profile, house_number: e.target.value })}
                margin="normal"
              />
            </Grid>
            <Grid size={2}>
              <TextField
                fullWidth
                label="หมู่ที่"
                value={profile.moo}
                onChange={(e) => setProfile({ ...profile, moo: e.target.value })}
                margin="normal"
              />
            </Grid>
            <Grid size={4}>
              <TextField
                fullWidth
                label="ซอย"
                value={profile.soi}
                onChange={(e) => setProfile({ ...profile, soi: e.target.value })}
                margin="normal"
              />
            </Grid>
            <Grid size={4}>
              <TextField
                fullWidth
                label="ถนน"
                value={profile.road}
                onChange={(e) => setProfile({ ...profile, road: e.target.value })}
                margin="normal"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid size={3}>
              <FormControl fullWidth margin="normal" >
                <InputLabel id="sub_district-label">ตำบล</InputLabel>
                <Select
                  labelId="sub_district-label"
                  id="sub_district"
                  value={profile.sub_district}
                  onChange={(e) => {
                    setProfile({ ...profile, sub_district: e.target.value });
                    fetchZipcode(e.target.value); // Fetch zipcode when sub-district changes
                  }}
                >
                  {subDistricts.map((subDistrict) => (
                    <MenuItem key={subDistrict.subdistricts_id} value={subDistrict.subdistricts_id}>
                      {subDistrict.subdistrict_name_in_thai}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid size={3}>
              <FormControl fullWidth margin="normal" >
                <InputLabel id="district-label">อำเภอ</InputLabel>
                <Select
                  labelId="district-label"
                  value={profile.district}
                  onChange={async (e) => {
                    const selectedDistrict = e.target.value;
                    setProfile({ ...profile, district: selectedDistrict });
                    await fetchSubDistricts(selectedDistrict); // Fetch sub-districts when district changes
                  }}

                >
                  {districts.map((district) => (
                    <MenuItem key={district.districts_id} value={district.districts_id}>
                      {district.district_name_in_thai}
                    </MenuItem>
                  ))}

                </Select>
              </FormControl>
            </Grid>
            <Grid size={3}>
              <FormControl fullWidth margin="normal">
                <InputLabel>จังหวัด</InputLabel>
                <Select
                  value={profile.province}
                  onChange={(e) => {
                    setProfile({ ...profile, province: e.target.value });
                    fetchDistricts(e.target.value); // Fetch districts when province changes
                  }}
                >
                  {provinces.map((province) => (
                    <MenuItem key={province.provinces_id} value={province.provinces_id}>
                      {province.province_name_in_thai}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid size={3}>
              <TextField
                fullWidth
                label="รหัสไปรษณีย์"
                value={profile.zipcode}
                //onChange={(e) => setProfile({ ...profile, zipcode: e.target.value })}
                margin="normal"
                disabled="true"
              />
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }} />

          <Grid size={12}>
            <Typography variant="h6" gutterBottom >
              ที่อยู่ปัจจุบัน
            </Typography>

          </Grid>
          <Grid size={12}>
            <FormGroup>
              <FormControlLabel control={ <Checkbox onChange={handleCheckboxChange}/>} label="ใช้ที่อยู่ตามทะเบียนบ้าน" />
            </FormGroup>
          </Grid>


          <Grid container spacing={2}>
            <Grid size={2}>
              <TextField
                fullWidth
                label="บ้านเลขที่"
                value={profile.current_house_number}
                onChange={(e) => setProfile({ ...profile, current_house_number: e.target.value })}
                margin="normal"
                disabled={useHomeAddress}
              />
            </Grid>
            <Grid size={2}>
              <TextField
                fullWidth
                label="หมู่ที่"
                value={profile.current_moo}
                onChange={(e) => setProfile({ ...profile, current_moo: e.target.value })}
                margin="normal"
                disabled={useHomeAddress}
              />
            </Grid>
            <Grid size={4}>
              <TextField
                fullWidth
                label="ซอย"
                value={profile.current_soi}
                onChange={(e) => setProfile({ ...profile, current_soi: e.target.value })}
                margin="normal"
                disabled={useHomeAddress}
              />
            </Grid>
            <Grid size={4}>
              <TextField
                fullWidth
                label="ถนน"
                value={profile.current_road}
                onChange={(e) => setProfile({ ...profile, current_road: e.target.value })}
                margin="normal"
                disabled={useHomeAddress}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid size={3}>
              <FormControl fullWidth margin="normal" >
                <InputLabel id="current_sub_district-label">ตำบล</InputLabel>
                <Select
                  labelId="sub_district-label"
                  id="current_sub_district"
                  value={profile.current_sub_district}
                  onChange={(e) => {
                    setProfile({ ...profile, current_sub_district: e.target.value });
                    fetchCZipcode(e.target.value); // Fetch zipcode when sub-district changes
                  }}
                  disabled={useHomeAddress}
                >
                  {c_subDistricts.map((subDistrict) => (
                    <MenuItem key={subDistrict.subdistricts_id} value={subDistrict.subdistricts_id}>
                      {subDistrict.subdistrict_name_in_thai}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid size={3}>
              <FormControl fullWidth margin="normal" >
                <InputLabel id="district-label">อำเภอ</InputLabel>
                <Select
                  labelId="district-label"
                  value={profile.current_district}
                  onChange={async (e) => {
                    const selectedDistrict = e.target.value;
                    setProfile({ ...profile, current_district: selectedDistrict });
                    await fetchCSubDistricts(selectedDistrict); // Fetch sub-districts when district changes
                  }}
                  disabled={useHomeAddress}

                >
                  {c_districts.map((district) => (
                    <MenuItem key={district.districts_id} value={district.districts_id}>
                      {district.district_name_in_thai}
                    </MenuItem>
                  ))}

                </Select>
              </FormControl>
            </Grid>
            <Grid size={3}>
              <FormControl fullWidth margin="normal">
                <InputLabel>จังหวัด</InputLabel>
                <Select
                  value={profile.current_province}
                  disabled={useHomeAddress}
                  onChange={(e) => {
                    setProfile({ ...profile, current_province: e.target.value });
                    fetchCDistricts(e.target.value); // Fetch districts when province changes
                  }}
                >
                  {c_provinces.map((province) => (
                    <MenuItem key={province.provinces_id} value={province.provinces_id}>
                      {province.province_name_in_thai}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid size={3}>
              <TextField
                fullWidth
                label="รหัสไปรษณีย์"
                value={profile.current_zipcode}
                //onChange={(e) => setProfile({ ...profile, zipcode: e.target.value })}
                margin="normal"
                disabled="true"
              />
            </Grid>
          </Grid>

          <Divider sx={{ my: 2 }} />

          <Grid size={12}>
            <Typography variant="h6" gutterBottom >
              ช่องทางติดต่อสื่อสาร
            </Typography>

          </Grid>

          <TextField
            fullWidth
            label="Email"
            value={profile.email}
            onChange={(e) => setProfile({ ...profile, email: e.target.value })}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Email (@sskru.ac.th)"
            value={profile.email_uni_google}
            onChange={(e) => setProfile({ ...profile, email_uni_google: e.target.value })}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Email (@365.sskru.ac.th)"
            value={profile.email_uni_microsoft}
            onChange={(e) => setProfile({ ...profile, email_uni_microsoft: e.target.value })}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Line"
            value={profile.line_acc}
            onChange={(e) => setProfile({ ...profile, line_acc: e.target.value })}
            margin="normal"
          />
          <TextField
            fullWidth
            label="facebook"
            value={profile.fb_acc}
            onChange={(e) => setProfile({ ...profile, fb_acc: e.target.value })}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Site"
            value={profile.personal_site}
            onChange={(e) => setProfile({ ...profile, personal_site: e.target.value })}
            margin="normal"
          />
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" color="primary" startIcon={<SaveOutlinedIcon />} onClick={handleSave}>
              บันทึกข้อมูล
            </Button>

          </Box>






        </CardContent>
      </Card>



    </Box>
  );
};

export default EditPersonal;
