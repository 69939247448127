import React, { useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem, SubMenu, SidebarFooter, } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, Badge } from "@mui/material";
import { Link } from "react-router-dom";
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom'; // นำเข้า useNavigate

// import icon
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import HomeIcon from '@mui/icons-material/Home';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import PersonIcon from '@mui/icons-material/Person';
import SchoolIcon from '@mui/icons-material/School';
import BusinessIcon from '@mui/icons-material/Business';
import SettingsIcon from '@mui/icons-material/Settings';
import PasswordIcon from '@mui/icons-material/Password';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const SideBar = () => {
  const [isCollapsed, setisCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(false);
  const [profile, setProfile] = useState({});
  const navigate = useNavigate(); // สร้าง instance ของ useNavigate
  const getPersonalIdFromToken = () => {
    const token = localStorage.getItem('hrm-sci-token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token); // Decode token
        return decodedToken.personal_id; // เข้าถึง personal_id
      } catch (error) {
        console.error('Failed to decode token:', error);
      }
    }
    console.log("Read token error !!");
    return null; // ถ้าไม่มี token หรือ decode ล้มเหลว

  };
  const personalId = getPersonalIdFromToken();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('hrm-sci-token');
        const response = await axios.post('https://sci-sskru.com/authen/profile', {
          personal_id: personalId 
        }, { headers: { Authorization: `Bearer ${token}` } });
        setProfile(response.data.profile); 
      } catch (error) {
        setError(error.response?.data?.message || 'ไม่สามารถเข้าถึงข้อมูลได้ !!');
        if(error.status == 401 || error.status == 403) {
          navigate('/authen/login');
        }
      } finally {
        setLoading(false);
      }
    };
    fetchProfile();
  }, []); 


  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  const handleSignOut = () => {
    // Remove token from localStorage
    localStorage.removeItem('hrm-sci-token');

    // Navigate to login page
    navigate('/authen/login');
  };
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
      }}
    >
      <Sidebar
        collapsed={isCollapsed}
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        onBreakPoint={setBroken}
        
        breakPoint="md"
        style={{ 
          height: "100%",
          backgroundColor: "#F7F6F3" }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <div style={{ flex: 1, marginBottom: "32px" }}>
            <Menu iconShape="square">
              {/* LOGO */}
              <MenuItem
                onClick={() => setisCollapsed(!isCollapsed)}
                icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                style={{
                  margin: "10px 0 20px 0",
                }}
              >
                {!isCollapsed && (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    ml="15px"
                  >
                    <Typography>SSKRU HRM</Typography>
                    <IconButton onClick={() => setisCollapsed(!isCollapsed)}>
                      <MenuOutlinedIcon />
                    </IconButton>
                  </Box>
                )}
              </MenuItem>
              {!isCollapsed && (
                <Box mb="25px">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img
                      alt="profile-user"
                      width="100px"
                      height="100px"

                      
                      src={`https://sci-sskru.com/authen${profile.img}`}
                      
                      style={{ cursor: "pointer", borderRadius: "50%" }}
                    />
                  </Box>
                  <Box textAlign="center">
                    <Typography sx={{ m: "10px 0 0 0" }}>{profile.first_name} {profile.last_name}</Typography>
                    <Typography>{profile.dept_name}</Typography>
                  </Box>
                </Box>
              )}

              <Link to="/profile/personal" className="menu-bars">
                <MenuItem icon={<HomeIcon />}>หน้าแรก</MenuItem>
              </Link>

              <SubMenu icon={<FolderSharedIcon />} label="ข้อมูลบุคลากร">
                <Link to={"/profile/personal"} className="menu-bars">
                  <MenuItem icon={<PersonIcon />}>
                    {" "}
                    ข้อมูลส่วนตัว
                  </MenuItem>
                </Link>
                <Link to={"/profile/education"} className="menu-bars">
                  <MenuItem icon={<SchoolIcon />}>
                    {" "}
                    ข้อมูลการศึกษา
                  </MenuItem>
                </Link>
                <Link to={"/profile/employee"} className="menu-bars">
                  <MenuItem icon={<BusinessIcon />}>
                    {" "}
                    ข้อมูลหน่วยงานสังกัด
                  </MenuItem>
                </Link>
              </SubMenu>
              <SubMenu label="การตั้งค่า" icon={<SettingsIcon />}>
                <Link to={"/setting/changepassword"} className="menu-bars">
                  <MenuItem icon={<PasswordIcon />}> รหัสผ่าน</MenuItem>
                </Link>
              </SubMenu>
              <MenuItem
                icon={<ExitToAppIcon />}
                className="menu-bars"
                onClick={handleSignOut}
              >
                ออกจากระบบ
            </MenuItem>
            </Menu>

            <div
              style={{
                padding: "0 24px",
                marginBottom: "8px",
                marginTop: "32px",
              }}
            >
              <Typography
                variant="body2"
                fontWeight={600}
                style={{
                  opacity: isCollapsed ? 0 : 0.5,
                  letterSpacing: "0.5px",
                }}
              >
                Extra
              </Typography>
            </div>

            <Menu>
              <MenuItem icon={<CalendarTodayOutlinedIcon />}>Calendar</MenuItem>
              <MenuItem icon={<ReceiptOutlinedIcon />}>Documentation</MenuItem>
            </Menu>
          </div>
          
        </div>
        
      </Sidebar>
      <main>
        <div style={{ padding: "16px 2px ", color: "#44596e" }}>
          <div style={{ marginBottom: "16px" }}>
            {broken && (
              <IconButton onClick={() => setToggled(!toggled)}>
                <MenuOutlinedIcon />
              </IconButton>
            )}
          </div>
        </div>
      </main>
      
    </div>
  );
};
export default SideBar;