import { useEffect } from 'react';
import HeaderBar from "./layout/HeaderBar";
import SideBar from "./layout/SideBar";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CssBaseline, Box } from "@mui/material";

import Dashboard from './components/dashboard/Dashboard';
import SignInMui from './components/signin/SignIn'

import Personal from "./components/profile/Personal";
import EditPersonal from "./components/profile/EditPersonal";

import Education from "./components/profile/Education";
import EditEducation from "./components/profile/EditEducation";

import Employee from "./components/profile/Employee";
import EditEmployee from "./components/profile/EditEmployee";

import ChangePassword from "./components/setting/ChangePassword";

import SignIn from "./components/authen/SignIn";
import SignUp from "./components/authen/SignUp";
import Login from "./components/authen/Login";

import Copyright from "./layout/Copyright";



function App() {
  useEffect(() => {
    if (window.location.pathname === "/") {
      window.location.replace("/hrm-front/");
    }
  }, []);
  return (
    < >
      <CssBaseline />
      <Routes>
        {/* Route สำหรับหน้า Login (ไม่ใช้ธีม) */}
        <Route path="/authen/login" element={<Login />} />
        <Route path="/authen/signin" element={<SignIn />} />
        <Route path="/authen/signup" element={<SignUp />} />
        <Route path="/authen/SignInMui" element={<SignInMui />} />

        {/* Route สำหรับหน้าอื่น ๆ ที่ใช้ธีม */}
        <Route path="/*" element={
          <div className="app">
            <SideBar />
            <main className="content">
              <HeaderBar />
              <div className="content_body">
                <Box m="20px">
                  <Routes>
                    <Route path="/" element={<Personal />} />
                    <Route path="/profile/personal" element={<Personal />} />
                    <Route path="/profile/edit-personal" element={<EditPersonal />} />
                    <Route path="/profile/education" element={<Education />} />
                    <Route path="/profile/edit-education" element={<EditEducation />} />
                    <Route path="/profile/employee" element={<Employee />} />
                    <Route path="/profile/edit-employee" element={<EditEmployee/>} />
                    <Route path="/setting/changepassword" element={<ChangePassword />} />
                  </Routes>
                </Box>
                <Copyright/>
              </div>
             
            </main>
           
          </div>
          
        } />
      </Routes>
    </>
  );
}

export default App;
