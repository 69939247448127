import React from 'react';
import { Card, CardContent, Typography, Avatar, Box, Divider, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom'; // นำเข้า useNavigate


function Personal() {
  const navigate = useNavigate(); // สร้าง instance ของ useNavigate
  const [profile, setProfile] = useState({ 
    personal_id: '',
    title_id: '',
    title_name: '',
    first_name: '',
    last_name: '',
    first_name_en: '',
    last_name_en: '',
    birth_date: '',
    mobile: '',
    house_number: '',
    moo: '',
    soi: '',
    road: '',
    sub_district: '',
    district: '',
    province: '',
    subdistrict_name: '',
    district_name: '',
    province_name: '',
    zipcode: '',
    current_house_number: '',
    current_moo: '',
    current_soi: '',
    current_road: '',
    current_sub_district: '',
    current_district: '',
    current_province: '',
    current_subdistrict_name: '',
    current_district_name: '',
    current_province_name: '',
    current_zipcode: '',
    email: '',
    email_uni_google: '',
    email_uni_microsoft: '',
    fb_acc: '',
    line_acc: '',
    personal_site: '',
    img: '',
   });


  const getPersonalIdFromToken = () => {
    const token = localStorage.getItem('hrm-sci-token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token); // Decode token
        return decodedToken.personal_id; // เข้าถึง personal_id
      } catch (error) {
        console.error('Failed to decode token:', error);
      }
    }
    console.log("Read token error !!");
    
    return null; // ถ้าไม่มี token หรือ decode ล้มเหลว

  };
  const personalId = getPersonalIdFromToken();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('hrm-sci-token');
        if (token == null) {
          navigate('/authen/login');
        }
        const response = await axios.post('https://sci-sskru.com/authen/profile', {
          personal_id: personalId 
        }, { headers: { Authorization: `Bearer ${token}` } });

        const profileData = response.data.profile;
        profileData.birth_date = new Date(profileData.birth_date).toISOString().split('T')[0];
        setProfile(profileData); 
      } catch (error) {
        setError(error.response?.data?.message || 'ไม่สามารถเข้าถึงข้อมูลได้ !!');
      } finally {
        setLoading(false);
      }
    };
    fetchProfile();
  }, []); 


  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  return (

    <Box sx={{ display: 'flex', }}>
      <Card sx={{ minWidth: 400, width: '100%' }}>
        <CardContent>
          {/* Avatar & Name */}
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar
              alt={profile.email}
              src={`https://sci-sskru.com/authen${profile.img}`}
             
              sx={{ width: 150, height: 150, mb: 2 }}
            />
            <Typography variant="h5" component="div">
              {profile.first_name_en} {profile.last_name_en}
            </Typography>
            <Typography color="textSecondary" gutterBottom>
              {profile.email}
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Personal Info */}
          <Grid container spacing={2}>
            <Grid size={12}>
              <Typography variant="body1" color="textPrimary">
                <strong>ชื่อ:</strong>  {profile.title_name}    &nbsp; {profile.first_name} &nbsp; {profile.last_name}
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography variant="body1" color="textPrimary">
                <strong>วันเกิด:</strong> {profile.birth_date}
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography variant="body1" color="textPrimary">
                <strong>เบอร์โทรศัพท์:</strong> {profile.mobile}
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography variant="body1" color="textPrimary">
                <strong>ที่อยู่ตามทะเบียนบ้าน:</strong> {profile.house_number} &nbsp; <strong>หมู่:</strong> &nbsp; {profile.moo} &nbsp; <strong>ซอย:</strong> &nbsp; {profile.soi} &nbsp; <strong>ถนน:</strong> &nbsp; {profile.road} &nbsp; <strong>ตำบล:</strong> &nbsp; {profile.subdistrict_name} &nbsp; <strong>อำเภอ:</strong> &nbsp; {profile.district_name} &nbsp; <strong>จังหวัด:</strong> &nbsp; {profile.province_name} &nbsp; <strong>รหัสไปรษณีย์:</strong> &nbsp;  {profile.zipcode}
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography variant="body1" color="textPrimary">
                <strong>ที่อยู่ปัจจุบัน:</strong> {profile.current_house_number} &nbsp; <strong>หมู่:</strong> &nbsp; {profile.current_moo} &nbsp; <strong>ซอย:</strong> &nbsp; {profile.current_soi} &nbsp; <strong>ถนน:</strong> &nbsp; {profile.current_road} &nbsp; <strong>ตำบล:</strong> &nbsp; {profile.current_subdistrict_name} &nbsp; <strong>อำเภอ:</strong> &nbsp; {profile.current_district_name} &nbsp; <strong>จังหวัด:</strong> &nbsp; {profile.current_province_name}  &nbsp; <strong>รหัสไปรษณีย์:</strong> &nbsp; {profile.current_zipcode}
                </Typography>
            </Grid>
            <Grid size={12}>
              <Typography variant="body1" color="textPrimary">
                <strong>อีเมล์:</strong> {profile.email}
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography variant="body1" color="textPrimary">
                <strong>อีเมล์ (@sskru.ac.th):</strong> {profile.email_uni_google}
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography variant="body1" color="textPrimary">
                <strong>อีเมล์ (@365.sskru.ac.th):</strong> {profile.email_uni_microsoft}
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography variant="body1" color="textPrimary">
                <strong>Line:</strong> {profile.line_acc}
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography variant="body1" color="textPrimary">
                <strong>Facebook:</strong> {profile.fb_acc}
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography variant="body1" color="textPrimary">
                <strong>Website:</strong> {profile.personal_site}
              </Typography>
            </Grid>

          </Grid>

          <Divider sx={{ my: 2 }} />

          {/* Actions */}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button href={`${process.env.PUBLIC_URL}/profile/edit-personal`}  variant="contained" color="primary" startIcon={<EditOutlinedIcon />} sx={{ mx: 1 }}>
              แก้ไขข้อมูล
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>

  );
}

export default Personal;
