import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

function Copyright() {
  return (
    <Typography variant="caption" color="text.secondary">
      {'Develop by '}
      <Link color="inherit" href="http://www.sci.sskru.ac.th/">
        Supachai Thongsuk 
      </Link>{' : สาขาวิศวกรรมซอฟต์แวร์ Copyright ©'}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
    
  );
}

const defaultTheme = createTheme();

export default function StickyFooter() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box>
        <CssBaseline />
        <Box
          component="footer"
          sx={{
            
          }}
        >
          <Container maxWidth="sm" >
            <Copyright />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}