import React from 'react';
import { Container, Card, CardContent, Typography, Avatar, Box, Divider, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";

function Employee() {

  

  const [employee, setEmployee] = useState({ 
    category_type_name: '',
    employee_type_name: '',
    academic_type_name: '',
    dept_name: '',
    manage_name: '',
    starting_date: '',
    resign_date: ''

   });

  const getPersonalIdFromToken = () => {
    const token = localStorage.getItem('hrm-sci-token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token); // Decode token
        return decodedToken.personal_id; // เข้าถึง personal_id
      } catch (error) {
        console.error('Failed to decode token:', error);
      }
    }
    console.log("Read token error !!");
    return null; // ถ้าไม่มี token หรือ decode ล้มเหลว

  };
  const personalId = getPersonalIdFromToken();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('hrm-sci-token');
        const response = await axios.post('https://sci-sskru.com/authen/profile', {
          personal_id: personalId
        }, { headers: { Authorization: `Bearer ${token}` } });
        const profileData = response.data.profile;
        profileData.starting_date = new Date(profileData.starting_date).toISOString().split('T')[0];
        profileData.resign_date = new Date(profileData.resign_date).toISOString().split('T')[0];
        setEmployee(profileData);
      } catch (error) {
        setError(error.response?.data?.message || 'ไม่สามารถเข้าถึงข้อมูลได้ !!');
      } finally {
        setLoading(false);
      }
    };
    fetchProfile();
  }, []);


  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  return (

    <Box sx={{ display: 'flex', }}>
      <Card sx={{ minWidth: 400, width: '100%' }}>
        <CardContent>

          <Typography variant="h6" align="center" gutterBottom>
            ข้อมูลหน่วยงานสังกัด
          </Typography>
          <Divider sx={{ my: 2 }} />

          {/* Personal Info */}
          <Grid container spacing={2}>
            <Grid size={12}>
              <Typography variant="body1" color="textPrimary">
                <strong>ประเภทสายงาน:</strong> {employee.category_type_name}
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography variant="body1" color="textPrimary">
                <strong>ประเภทบุคลากร:</strong> {employee.employee_type_name}
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography variant="body1" color="textPrimary">
                <strong>ตำแหน่งทางวิชากร:</strong> {employee.academic_type_name}
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography variant="body1" color="textPrimary">
                <strong>สังกัด:</strong> {employee.dept_name}
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography variant="body1" color="textPrimary">
                <strong>คณะ:</strong> ศิลปศาสตร์และวิทยาศาสตร์
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography variant="body1" color="textPrimary">
                <strong>วันที่เริ่มปฏิบัติงาน:</strong> {employee.starting_date}
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography variant="body1" color="textPrimary">
                <strong>วันที่สิ้นสุดการทำงาน:</strong> {employee.resign_date}
              </Typography>
            </Grid>

          </Grid>

          <Divider sx={{ my: 2 }} />

          {/* Actions */}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button href={`${process.env.PUBLIC_URL}/profile/edit-employee`}  variant="contained" color="primary" startIcon={<EditOutlinedIcon />} sx={{ mx: 1 }}>
              แก้ไขข้อมูล
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>

  );
}

export default Employee;
