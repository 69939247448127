import React, { useState } from 'react';
import { Avatar, Button, TextField, Box, Typography, Card, CardContent, Container, CssBaseline } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import Grid from '@mui/material/Grid2';

function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic สำหรับการเปลี่ยนรหัสผ่าน
    if (newPassword !== confirmPassword) {
      alert('รหัสผ่านใหม่ไม่ตรงกัน');
      return;
    }
    console.log('เปลี่ยนรหัสผ่านสำเร็จ');
  };

  return (
    <Box sx={{ display: 'flex', }}>
     <Card sx={{ minWidth: 350, width: '100%' }}>
     <CardContent>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: 2,
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          เปลี่ยนรหัสผ่าน
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid size={12}>
              <TextField
                disabled
                required
                fullWidth
                name="currentPassword"
                label="รหัสผ่านปัจจุบัน"
                type="password"
                id="currentPassword"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </Grid>
            <Grid size={12}>
              <TextField
                disabled
                required
                fullWidth
                name="newPassword"
                label="รหัสผ่านใหม่"
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Grid>
            <Grid size={12}>
              <TextField
                disabled
                required
                fullWidth
                name="confirmPassword"
                label="ยืนยันรหัสผ่านใหม่"
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Grid>
          </Grid>
          <Button
            disabled
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }} startIcon={<LockIcon />}
          >
            เปลี่ยนรหัสผ่าน
          </Button>
        </Box>
      </Box>
      </CardContent>
      </Card>
    </Box>
  );
}

export default ChangePassword;
